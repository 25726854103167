<template>
    <el-card class="box-card-b" shadow="never">
        <el-form :model="lookup.data" :inline="true" label-position="top" :loading='loading'>
            <el-row>
                <el-col v-for="(item, index) in changeHeads" :key="index" :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
                    <el-form-item :label="item.label" label-position="left" label-width='100%'>
                        <el-input v-model="lookup.data[item.property]" v-if="item.type === 'number'" type="number"
                            :disabled='!item.edit' />
                        <el-input v-model="lookup.data[item.property]" v-if="item.type === 'input'" style="width: 450px"
                            :disabled='!item.edit' />
                        <el-input v-model="lookup.data[item.property]" :rows="2" type="textarea"
                            v-if="item.type === 'textarea'" :disabled='!item.edit' />
                        <el-select v-model="lookup.data[item.property]" placeholder="选择" v-if="item.type === 'select'"
                            filterable :disabled='!item.edit'>
                            <el-option v-for="i in item.option" :key="i" :label="i" :value="i" />
                        </el-select>
                        <el-date-picker v-model="lookup.data[item.property]" type="date" placeholder="选择时间"
                            format="YYYY-MM-DD" value-format="YYYY-MM-DD" range-separator="-"
                            v-if="item.type === 'date-picker'" :disabled='!item.edit' />
                        <el-date-picker v-model="lookup.data[item.property]" type="date" placeholder="选择时间"
                            format="YYYY-MM-DD" value-format="YYYY-MM-DD" range-separator="-"
                            v-if="item.type === 'one-date-picker'" :disabled='!item.edit' />
                        <el-cascader v-model="lookup.data[item.property]" :options="item.option" :props="props"
                            collapse-tags clearable v-if="item.type === 'cascader'" :disabled='!item.edit' />
                        <el-input v-model="lookup.data[item.property]" :rows="2" v-if="item.type === 'disabled'"
                            disabled />
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>
        <span class="dialog-footer">
            <el-button type="primary" @click="updatas">
                确认
            </el-button>
        </span>
    </el-card>
</template>

<script>
    import axios from "@/utils/request";
    import router from '@/router'
    import {
        ElMessage,
        ElLoading,
    } from 'element-plus'
    export default {
        name: 'MimiForm',
        data() {
            return {
                olddata: {},
                changeHeads: [{
                    label: '密钥',
                    property: 'keyword',
                    type: 'input',
                    edit: true
                }],
                loading: false,
                lookup: {
                    tabname: '',
                    uri: this.$route.query.uri,
                    id: this.$route.query.id,
                    data: {},
                    modifyobj: {},
                    modifysrt: ''
                }
            }
        },
        created() {},
        methods: {
            updatas() {
                this.loading = true
                // 统计数据变化开始
                let modifysrt = []
                this.changeHeads.forEach((items) => {
                    if (items.edit && this.olddata[items.property] != this.lookup.data[items.property]) {
                        modifysrt.push(
                            `${items.label}:${this.olddata[items.property]}=>${this.lookup.data[items.property]}`
                        )
                    }
                })
                if (modifysrt.length == 0) {
                    ElMessage({
                        type: "warning",
                        message: `数据无变化！`
                    })
                    return
                }
                this.lookup.modifysrt = modifysrt.join(',')
                // 统计数据变化结束
                axios({
                    method: "post",
                    url: 'mimiform',
                    data: this.lookup
                }).then(res => {
                    if (this.lookup.tabname != '') {
                        if (res.length) {
                            ElMessage({
                                type: "success",
                                message: `操作成功！`
                            })
                            // 更新旧数据
                            this.olddata = JSON.parse(JSON.stringify(this.lookup.data))
                        }
                    } else {
                        if (res.tabname) {
                            this.changeHeads = res.tableHead.tableHead
                            this.olddata = JSON.parse(JSON.stringify(res.data))
                            this.lookup.data = res.data
                            this.lookup.tabname = res.tabname
                            ElMessage({
                                type: "success",
                                message: `密钥验证成功！`
                            })
                        } else {
                            ElMessage({
                                type: "warning",
                                message: `密钥验证失败！`
                            })
                        }
                    }
                    this.loading = false
                })
            }
        }
    }
</script>

<style scoped>
</style>